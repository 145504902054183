import React, { useContext, useState } from 'react';
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Snackbar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import InfoIcon from '@mui/icons-material/Info';
import './css/CVService.css';
import { SUPPORT_CONTACT_EMAIL } from './utils/Constants';
import { CvOptimisationRequestTemplateParams, logToBackendLogFile, sendCVOptimisationRequestToBackend } from './externalLayerAccessor/BackEndRequests';
import StateAccessor from './StateAccessor';
import { openUrlInNewTab } from './utils/HelperFunctions';


const CV_TEXT_LIMIT = 6500; // roughly for a 2-page document, but adjust accordingly
const CV_EXTRA_TEXT_LIMIT = 2000; // adjust this as per requirement

enum PaymentUrl {
    CRITIQUE = 'https://buy.stripe.com/8wMfZI97p8FZ0Lu28l',
    REWRITE = 'https://buy.stripe.com/bIY28Sbfx5tNgKs28n',
}

interface State {
    cvText: string;
    cvExtraText: string;
    serviceType: string;
    email: string;
    confirmEmail: string;
    notificationOpen: boolean;
}

enum ServiceType {
    CRITIQUE = 'ServiceType.CRITIQUE',
    IMPROVE = 'ServiceType.IMPROVE',
    REWRITE = 'ServiceType.REWRITE',
    TAILOR_ROLE = 'ServiceType.TAILOR_ROLE',
    TAILOR_COMPANY = 'ServiceType.TAILOR_COMPANY',
    UNDEFINED = 'ServiceType.UNDEFINED',
}

const CVService: React.FC = () => {
    const { userId } = useContext(StateAccessor);
    const [cvText, setCvText] = useState('');
    const [cvExtraText, setCvExtraText] = useState('');
    const [serviceType, setServiceType] = useState<ServiceType>(ServiceType.UNDEFINED);

    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [successfulSubmissionNotificationOpen, setSuccessfulSubmissionNotificationOpen] = useState(false);
    const [failedSubmissionNotificationOpen, setFailedSubmissionNotificationOpen] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const showSubmittableForm = (): boolean => {
        return serviceType !== null && serviceType !== undefined && serviceType !== ServiceType.UNDEFINED;
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string, value: unknown }>) => {
        const name = e.target.name as keyof State;
        const value = e.target.value;
        switch (name) {
            case 'cvText':
                setCvText(value as string);
                break;
            case 'cvExtraText':
                setCvExtraText(value as string);
                break;
            case 'serviceType':
                setServiceType(value as ServiceType);
                break;
            case 'email':
                setEmail(value as string);
                break;
            case 'confirmEmail':
                setConfirmEmail(value as string);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (paymentUrl: string) => {
        try {
            setIsSubmitting(true);
            // if email is an empty string or null or undefined 
            if (!email) {
                alert("Please enter an email address!");
                return;
            }
            if (email !== confirmEmail) {
                alert("Email addresses do not match!");  // Or handle this in a more UX-friendly way
                return;
            }
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                alert("Please enter a valid email address!");
                return;
            }


            // Prepare the template parameters
            const templateParams: CvOptimisationRequestTemplateParams = {
                serviceType: serviceType.toString(),
                customerEmail: email,
                cvText: cvText,
                cvExtraText: cvExtraText,
            };

            // Call the backend to send the email
            const isSuccess = await sendCVOptimisationRequestToBackend(templateParams);


            if (isSuccess) {
                openUrlInNewTab(paymentUrl, userId)
                setSuccessfulSubmissionNotificationOpen(true);
                setServiceType(ServiceType.UNDEFINED);
            } else {
                setFailedSubmissionNotificationOpen(true);
                logToBackendLogFile(
                    `Failed to submit CV optimisation request for email: ${email}`,
                    "info",
                    userId);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSuccessfulSubmissionNotificationClose = () => {
        setSuccessfulSubmissionNotificationOpen(false);
    };

    const handleFailedSubmissionNotificationClose = () => {
        setFailedSubmissionNotificationOpen(false);
    };

    const handleSelectChange = (e: any) => {
        const value = e.target.value;
        setServiceType(value as ServiceType);
    };

    const getPaymentUrlByServiceType = (serviceType: ServiceType): string => {
        switch (serviceType) {
            case ServiceType.CRITIQUE:
                return PaymentUrl.CRITIQUE;
            case ServiceType.REWRITE:
                return PaymentUrl.REWRITE;
            default:
                return window.location.href; // return the current page URL if no payment URL is available
        };
    }

    const renderLeftColumnContent = () => {
        switch (serviceType) {
            case 'ServiceType.CRITIQUE':
                return (
                    <>
                        <InfoIcon />
                        <h2>Critique My CV</h2>
                        <p><b>Objective Insights:</b> Get unbiased feedback and know where you stand.</p>
                        <p><b>Highlight Opportunities:</b> We pinpoint what can be enhanced to make a more compelling CV.</p>
                        <p><b>Strategic Feedback:</b> Know what matters most to hiring managers and recruiters.</p>
                        <p className='highlight'>Price: $89.99</p>
                    </>
                );
            case 'ServiceType.IMPROVE':
                return (
                    <>
                        <InfoIcon />
                        <h2>Improve My CV</h2>
                        <p><b>Expert Enhancements:</b> We infuse your CV with industry-specific insights for greater impact.</p>
                        <p><b>Stay Relevant:</b> Outshine others with a CV that meets current market standards.</p>
                        <p><b>ATS Optimization:</b> Beat the bots and get noticed with an Applicant Tracking System-friendly CV.</p>
                        <p>Price: FREE</p>
                    </>
                );
            case 'ServiceType.REWRITE':
                return (
                    <>
                        <InfoIcon />
                        <h2>Rewrite My CV</h2>
                        <p><b>Start Fresh:</b> We recreate your CV, ensuring it speaks volumes about your skills and experience.</p>
                        <p><b>Market Ready:</b> Position yourself as a top candidate with a revamped CV.</p>
                        <p><b>Make It Count:</b> Every word on your CV will be strategically placed for maximum effect.</p>
                        <p className='highlight'>Price: $299.99</p>
                    </>
                );
            case 'ServiceType.TAILOR_ROLE':
                return (
                    <>
                        <InfoIcon />
                        <h2>Tailor My CV to a Specific Role</h2>
                        <p><b>Pinpoint Precision:</b> Ensure every word resonates with the role you're targeting.</p>
                        <p><b>Higher Success:</b> Improve your chances by being the perfect fit on paper.</p>
                        <p><b>Custom Crafted:</b> Your CV will be handcrafted to align with your dream role's requirements.</p>
                        <p>Price: FREE</p>
                    </>
                );
            case 'ServiceType.TAILOR_COMPANY':
                return (
                    <>
                        <InfoIcon />
                        <h2>Tailor My CV to a Company</h2>
                        <p><b>Company Aligned:</b> Reflect the company's values and vision in your CV.</p>
                        <p><b>Speak Their Language:</b> Use terminologies and phrases that resonate with the target company.</p>
                        <p><b>Stand Out:</b> Be remembered as the candidate who did their homework.</p>
                        <p>Price: FREE</p>
                    </>
                );
            default:
                return (
                    <>
                        <h2>Why Use Our Services?</h2>
                        <p>Getting an interview is the first step in landing your dream job.</p>
                        <p>A standout CV is key, especially for those targeting competitive roles in a fierce job market.</p>
                        <p>Our services ensure your CV stands out for all the right reasons.</p>
                        <p><strong> Select the service that best fits your needs to get started.</strong></p>
                    </>
                );
        }
    };


    return (
        <div className="cv-service-container">
            <div className="explanations-side-bar">
                {renderLeftColumnContent()}
            </div>
            <div className="cv-services">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="service-type">Service Type</InputLabel>
                    <Select
                        value={serviceType}
                        onChange={handleSelectChange}
                        label="Service Type"
                        name="serviceType"
                        id="service-type"
                    >
                        <MenuItem value={ServiceType.CRITIQUE} disabled>Coming Soon Critique My CV</MenuItem>
                        <MenuItem value={ServiceType.REWRITE} disabled>Coming Soon Rewrite My CV</MenuItem>
                        <MenuItem value={ServiceType.IMPROVE} disabled>Coming Soon: Improve My CV</MenuItem>
                        <MenuItem value={ServiceType.TAILOR_ROLE} disabled>Coming Soon: Tailor My CV to a Specific Role</MenuItem>
                        <MenuItem value={ServiceType.TAILOR_COMPANY} disabled>Coming Soon: Tailor My CV to a Company</MenuItem>
                    </Select>
                </FormControl>
                {showSubmittableForm() && (
                    <>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            margin="normal"
                            label="Paste your CV contents here (feel free to remove personal details)."
                            name="cvText"
                            value={cvText}
                            onChange={handleChange}
                            inputProps={{ maxLength: CV_TEXT_LIMIT }}
                            helperText={`${cvText.length}/${CV_TEXT_LIMIT}`}
                        />

                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            margin="normal"
                            label="(Optional): Include extra information that isn't on your CV but could potentially be useful"
                            name="cvExtraText"
                            value={cvExtraText}
                            onChange={handleChange}
                            inputProps={{ maxLength: CV_EXTRA_TEXT_LIMIT }}
                            helperText={`${cvExtraText.length}/${CV_EXTRA_TEXT_LIMIT}`}
                        />


                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Email Address"
                            autoComplete='email'
                            name="email"
                            type="email"
                            value={email}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Confirm Email Address"
                            autoComplete='email'
                            name="confirmEmail"
                            value={confirmEmail}
                            onChange={handleChange}
                            type="email"
                            error={email !== confirmEmail}
                            helperText={email !== confirmEmail ? "Email addresses do not match" : ""}
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<SendIcon />}
                            onClick={ () => handleSubmit(getPaymentUrlByServiceType(serviceType))}
                            disabled={isSubmitting}
                            style={{ 
                                textTransform: 'none',
                                maxWidth: '200px',
                            }}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit and Pay'}
                        </Button>
                    </>
                )}
                <Snackbar
                    open={successfulSubmissionNotificationOpen}
                    onClose={handleSuccessfulSubmissionNotificationClose}
                    message={`Details received. Feedback will be sent to ${email} when ready`}
                    style={{ backgroundColor: '#28a5b3', color: 'white'}}
                />
                <Snackbar
                    open={failedSubmissionNotificationOpen}
                    onClose={handleFailedSubmissionNotificationClose}
                    message={`Failed to submit details. Please try again or contact support at ${SUPPORT_CONTACT_EMAIL}`}
                    style={{ backgroundColor: 'rgb(231, 67, 67)', color: 'white'}}
                />
            </div>
        </div>
    );
};

export default CVService;