import React, { useContext, useEffect, useState } from 'react';
import ChallengeDescription from './ChallengeDescription';
import SolutionContent from './SolutionContent';
import '../css/ChallengeTabbedView.css';
import { Challenge, ChallengeDescriptionInterface, ChallengeType, ChatMessage, CodeBlockByLanguage, ExplanationsInterface, Unit, UserEntitlements } from '../utils/Constants';
import Explanations from './Explanations';
import MenuIcon from '@mui/icons-material/Menu';
import { CourseChallengeTabs } from './Courses';
import AiAssistantChatWindow, { AIInteractionContext } from '../AiAssistantChatWindow';
import { ProgrammingLanguage } from './Settings';
import StateAccessor from '../StateAccessor';
import TutorialContent from './TutorialContent';

interface ChallengeTabbedView {
  unit: Unit;
  challenge: Challenge;
  acceptableAnswers: string[]
  challengeTimeLimit: number;
  challengeDescriptionValues: ChallengeDescriptionInterface
  solutionByLanguage: CodeBlockByLanguage | null;
  showSolutionView: boolean;
  activeTab?: string;
  handleToggleActiveTab?: (tab: CourseChallengeTabs) => void;
  openSidebar?: () => void;
  assessmentTitle?: string;
  currentChallengeRank: number;
  isAssessment?: boolean;
  disabled?: boolean;
  programmingLanguage?: ProgrammingLanguage;
  candidateCode?: string;
  
}

const ChallengeTabbedView: React.FC<ChallengeTabbedView> = ({
  unit,
  challenge,
  activeTab,
  handleToggleActiveTab = (tab: CourseChallengeTabs) => { },
  acceptableAnswers, // NITO: TODO: deprecate this and other props derivable from challenge prop
  challengeTimeLimit,
  solutionByLanguage,
  showSolutionView,
  challengeDescriptionValues,
  assessmentTitle,
  openSidebar,
  currentChallengeRank,
  isAssessment = false,
  disabled = false,
  programmingLanguage = ProgrammingLanguage.Python,
  candidateCode = "",
}) => {
  if (disabled) { return null; }

  const { userId, userEmail, userName } = useContext(StateAccessor);
  const [userEntitlements] = React.useState<UserEntitlements>({
    isAiInterviewerFeatureEnabled: true,

  });

  /////////////////////////////////
  // AI Assistant
  /////////////////////////////////
  const [aiAssistantChatHistory, setAiAssistantChatHistory] = useState<ChatMessage[]>([]);


  /////////////////////////////////
  // Functions
  /////////////////////////////////
  /*
  * This function is called when the AI assistant sends a new message or when the user sends a new message to the AI assistant.
  */
  const handleNewAiAssistantInteractionChatMessage = (newChatMessage: ChatMessage) => {
    // Update the chat history state with the new message
    setAiAssistantChatHistory(prevAiAssistantChatHistory => [...prevAiAssistantChatHistory, newChatMessage]);
  };

  const initialize = () => {

    // set active tab to tutorial if challenge type is tutorial
    if (challenge.challenge_type === ChallengeType.TUTORIAL) {
      handleToggleActiveTab(CourseChallengeTabs.TUTORIAL);
    } else {
      handleToggleActiveTab(CourseChallengeTabs.PROBLEM);
    }
  }

  const renderContent = () => {
    if (activeTab === CourseChallengeTabs.EXPLANATIONS) {
      return (
      <div className='content-container'>
        <Explanations
          unit={unit}
        />
      </div>
    );
    }
    else if (activeTab === CourseChallengeTabs.SOLUTIONS) {
      return (
      <div className='content-container'>
        <SolutionContent
          unit={unit}
          acceptableAnswers={acceptableAnswers}
          challenge={challenge}
          solutionByLanguage={solutionByLanguage || undefined}
        />
      </div>
    );
    } else if (activeTab === CourseChallengeTabs.AI_ASSISTANT) {
      const interactionContext = { //TODO: use memo, but beware of runtime issue about hooks
        userId: userId,
        userEmail: userEmail,
        candidateName: userName,
        chatHistory: aiAssistantChatHistory,
        challenge: challenge,
        candidatesCode: candidateCode,
        programmingLanguage: programmingLanguage,
      };

      return (
        <div className='content-container'>
          <AiAssistantChatWindow
            isInteractionEnabled={userEntitlements.isAiInterviewerFeatureEnabled}
            aiInteractionContext={AIInteractionContext.InterviewCoaching}
            interactionContext={interactionContext}
            newChatMessageHandler={handleNewAiAssistantInteractionChatMessage}


          />
        </div>
      );
    } else if (activeTab === CourseChallengeTabs.PROBLEM) {
      return (
        <div className='content-container'>
          <ChallengeDescription
            currentChallengeRank={currentChallengeRank}
            challengeDescriptionValues={challengeDescriptionValues}
          />
        </div>
      );
    } else if (activeTab === CourseChallengeTabs.TUTORIAL) {
      return (
        <div className='content-container'>
          <TutorialContent
            unit={unit}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const shouldShowSolutionGuidanceTab = ():boolean => {
    return showSolutionView;
  }

  const shouldShowSolutionAnswerOnlyTab = (): boolean => {
    return showSolutionView && (
      challenge.challenge_type === ChallengeType.CODING || 
      challenge.challenge_type === ChallengeType.KNOWLEDGE || 
      challenge.challenge_type === ChallengeType.LOW_LEVEL_DESIGN || 
      challenge.challenge_type === ChallengeType.Algorizing
    );
  }

  const shouldShowProblemTab = (): boolean => {
    return challenge.challenge_type !== ChallengeType.TUTORIAL;
  }

  const shouldShowAiAssistantTab = (): boolean => {
    return (
      challenge.challenge_type === ChallengeType.Algorizing || 
      challenge.challenge_type === ChallengeType.CODING || 
      challenge.challenge_type === ChallengeType.LOW_LEVEL_DESIGN
    );
  }

  const shouldShowTutorialLessonTab = (): boolean => {
    return challenge.challenge_type === ChallengeType.TUTORIAL && !!challenge.tutorial;
  }

  //////////////////////////////////
  // UseEffect
  //////////////////////////////////

  useEffect(() => {
    initialize();
  }, [challenge]);

  return (
    <div className='challenge-box-container'>
      {!isAssessment && (
        <div className='challenge-navbar'>

          {shouldShowTutorialLessonTab() && (
            <span
              className={`challenge-navbar-item ${activeTab === CourseChallengeTabs.TUTORIAL ? 'active-option' : ''
                }`}
              onClick={() => handleToggleActiveTab && handleToggleActiveTab(CourseChallengeTabs.TUTORIAL)}
            >
              📖 Lesson Article
            </span>
          )}

          {shouldShowProblemTab() && (
            <span className={`challenge-navbar-item ${activeTab === CourseChallengeTabs.PROBLEM ? 'active-option' : ''
              }`}
            onClick={() => handleToggleActiveTab && handleToggleActiveTab(CourseChallengeTabs.PROBLEM)}
          >
            ❓ Problem
          </span>
          )}

          {shouldShowAiAssistantTab() && (
            <span
              className={`challenge-navbar-item ai-assistant-tab ${activeTab === CourseChallengeTabs.AI_ASSISTANT ? 'active-option' : ''
                }`}
              onClick={() => handleToggleActiveTab && handleToggleActiveTab(CourseChallengeTabs.AI_ASSISTANT)}
            >
              🧠 AI Assistant
            </span>
          )}

          {shouldShowSolutionGuidanceTab() && (
            <span className={`challenge-navbar-item ${activeTab === CourseChallengeTabs.EXPLANATIONS ? 'active-option' : ''
              }`}
            onClick={() => handleToggleActiveTab && handleToggleActiveTab(CourseChallengeTabs.EXPLANATIONS)}
            >
              🗺️ Solution (Guidance)
            </span>
          )}

          {shouldShowSolutionAnswerOnlyTab() && (
            <span
              className={`challenge-navbar-item ${activeTab === CourseChallengeTabs.SOLUTIONS ? 'active-option' : ''
                }`}
              onClick={() => handleToggleActiveTab && handleToggleActiveTab(CourseChallengeTabs.SOLUTIONS)}
            >
              ✅ {challenge.challenge_type === ChallengeType.CODING || challenge.challenge_type === ChallengeType.LOW_LEVEL_DESIGN || challenge.challenge_type === ChallengeType.Algorizing ? "Solution (Code)" : "Answer"}
            </span>
          )}

        </div>
      )}
      {!isAssessment ? renderContent() :
        <>
          <div>
            <div className='challenge-navbar'>
              {openSidebar && (
                <span
                  className='hamburger-icon'
                  onMouseEnter={openSidebar}
                >
                  <MenuIcon />
                </span>
              )}
              {assessmentTitle && (
                <h2 className='navbar-assessment-title'>{assessmentTitle}</h2>
              )}
            </div>
            <ChallengeDescription isAssessment={isAssessment}
              currentChallengeRank={currentChallengeRank}
              challengeDescriptionValues={challengeDescriptionValues}
            />
          </div>
        </>
      }
    </div>
  );
};

export default ChallengeTabbedView;
