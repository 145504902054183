import axios, { AxiosError } from 'axios';
import { InterviewSettings } from '../pages/Settings'
import { CandidatesMessageIntent, ChatMessageSender, Phase, TakeHomeAssessmentInterviewState, TelephoneOrOnsiteInterviewState, UserExperienceFeedback, Course, Assessment, TestCaseExecutionRequest, TestCaseExecutionResponse, UserSpecialAbility, UserProgress, InterviewerCoachInteractionContext, ChatMessage, ExecutionStatus, MinifiedCourse } from '../utils/Constants'
import { CodeExecutionAPIEndpoints, TakeHomeAssessmentInterviewAPIEndpoints, TelephoneOrOnsiteInterviewAPIEndpoints, UserExperienceAPIEndpoints } from '../utils/ApiEndpoints';

export const REACT_APP_BACK_END_BASE_URL = process.env.REACT_APP_BACK_END_BASE_URL || 'https://coditioning.herokuapp.com'; // nebug: UPDATE DEFAULT TO CUSTOM DOMAIN
const REACT_APP_BACK_END_CODE_EXECUTION_BASE_URL = process.env.REACT_APP_BACK_END_CODE_EXECUTION_BASE_URL || 'https://coditioning-code-executor-765458e592ca.herokuapp.com';

enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

interface User {
  id: number;
  username: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  createdAt: Date;
}

//////////////////////////
// AUXILIARY FUNCTIONS //
//////////////////////////
type RequestFunction<T> = () => Promise<T>;

export async function retryRequest<T>(
  requestFn: RequestFunction<T>,
  retryLimit: number = 3,              // Total attempts = 3 (i.e., 2 retries)
  retryDelayInSeconds: number = 2000   // 2 seconds delay between retries
): Promise<T> {
  let attempts = 0;

  while (attempts < retryLimit) {
    try {
      return await requestFn();
    } catch (error: any) {
      // Using optional chaining to safely access error.response.data.code
      const errorCode = error.response?.data?.code;
      
      const isRetriable =
        error.response &&
        (
          error.response.status === 503 ||
          (error.response.status === 502 && errorCode === 5000)
        );

      if (isRetriable && attempts < retryLimit - 1) {
        await new Promise(res => setTimeout(res, retryDelayInSeconds));
        attempts++;
      } else {
        throw error; // Non-retriable error or retry limit reached.
      }
    }
  }
  
  throw new Error("Reached retry limit without a successful response.");
}



/*
To be used when http request return non 200 status code to generate appropriate chat message
It enables graceful handling of errors
*/
function generateChatMessageFromErrorResponse(error: any): ChatMessage {
  // Check for error response and its status
  if (error.response && error.response.status === 402) {
    return new ChatMessage(ChatMessageSender.System, ChatMessageSender.Candidate, new Date().toISOString(), "You have insufficient AI-usage tokens left.");
  } else if (error.response && error.response.status === 503) {// heroku returns this on timeout
    return new ChatMessage(ChatMessageSender.System, ChatMessageSender.Candidate, new Date().toISOString(), "I'm taking too long to respond. If you ask me to do lots of work, I might not be able to respond in time, try reducing the amount of work you ask me to do");
  }

  // Log the error
  if (error instanceof Error) {
    console.error('Error:', error.message);
  } else {
    console.error('Error:', error);
  }

  // Return a generic error message
  return new ChatMessage(ChatMessageSender.System, ChatMessageSender.Candidate, new Date().toISOString(), "Could you try that again, I had some temporary issues.");
}



export class ChallengeDetails {
  problemDescription: string;
  programmingLanguage: string;
  codeEditorContent: string;
  durationInMinutes: number;

  constructor(problemDescription: string, programmingLanguage: string, codeEditorContent: string, durationInMinutes: number) {
    this.problemDescription = problemDescription;
    this.programmingLanguage = programmingLanguage;
    this.codeEditorContent = codeEditorContent;
    this.durationInMinutes = durationInMinutes;
  }
}

export class InterviewState {
  chatHistory: ChatMessage[];
  candidatesCode: string;
  programmingLanguage: string;
  isCodeSubmitted: boolean;
  candidateName: string;
  codingChallengeQuestion: string;
  interviewSettings: InterviewSettings;
  telephoneOrOnsitePhase?: Phase | null;
  userEmail?: string | null;
  userId?: number | null;

  constructor(chatHistory: ChatMessage[], candidatesCode: string, programmingLanguage: string,
    isCodeSubmitted: boolean, candidateName: string, codingChallengeQuestion: string,
    interviewSettings: InterviewSettings, telephoneOrOnsitePhase?: Phase, userEmail?: string,
    userId?: number
  ) {
    this.chatHistory = chatHistory;
    this.candidatesCode = candidatesCode;
    this.programmingLanguage = programmingLanguage;
    this.isCodeSubmitted = isCodeSubmitted;
    this.candidateName = candidateName;
    this.codingChallengeQuestion = codingChallengeQuestion;
    this.interviewSettings = interviewSettings;
    this.telephoneOrOnsitePhase = telephoneOrOnsitePhase;
    this.userEmail = userEmail;
    this.userId = userId;

  }
}



export async function getChallengeDetails(
  problemDifficulty: number,
  programmingLanguage: string,
  durationInMinutes: number,
  userId: number): Promise<ChallengeDetails> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/challenge/difficulty/${problemDifficulty}`
  const params = {
    "programmingLanguage": programmingLanguage,
    "durationInMinutes": durationInMinutes,
    "userId": userId
  }
  try {
    const response = await axios.get(url, { params: params })
    console.log(`successfully received challenge details, response: ${JSON.stringify(response.data)}`);

    const problemDescription = response.data.challenge_description
    const receivedProgrammingLanguage = response.data.programming_language
    const codeEditorContent = response.data.code_stub
    const receivedDurationInMinutes = response.data.duration_in_minutes

    return new ChallengeDetails(problemDescription, receivedProgrammingLanguage, codeEditorContent, receivedDurationInMinutes);
  }
  catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 404) {
        return new ChallengeDetails("We currently do not have a challenge that matches the selected settings. Please consider adjusting the difficulty, duration, or interview stage. We are diligently expanding our question bank and appreciate your patience.",
          "cpp", "", durationInMinutes);
      }
      console.error('Error:', axiosError.message);
    } else {
      console.error('Error:', error);
    }
  }
  return new ChallengeDetails("There was an error whilst retrieving your challenge, we're sorry for the inconvenience.", "cpp", "", durationInMinutes);
}

export async function getUserDetailsByEmail(userEmail: string): Promise<User> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/users`;
  const queryParams = {
    "email": userEmail
  }

  // Build query string from queryParams
  let queryString = "";
  if (queryParams) {
    queryString = "?" + new URLSearchParams(queryParams).toString();
  }

  console.log(`sending request, queryString: ${queryString}`)
  try {
    const response = await axios.get(url + queryString);
    const userDetails: User = {
      id: response.data.id,
      username: response.data.userName,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      email: response.data.email,
      createdAt: response.data.createdAt,
    }
    return userDetails;
  } catch (error: unknown) {
    console.error(`failed to get response, url: ${url}`);
    if (error instanceof Error) {
      console.error('Error:', error.message);
    } else {
      console.error('Error:', error);
    }
    throw new Error("Failed to get user details");
  }
}

export async function sendRequest(
  url: string,
  interviewState: InterviewState,
  queryParams?: { [key: string]: string }
): Promise<ChatMessage> {
  //log queryParams received
  console.log(`queryParams: ${JSON.stringify(queryParams)}`);

  const payload = {
    "chatHistory": interviewState.chatHistory,
    "candidatesCode": interviewState.candidatesCode,
    "programmingLanguage": interviewState.programmingLanguage,
    "codeIsSubmitted": interviewState.isCodeSubmitted,
    "candidateName": interviewState.candidateName,
    "codingChallengeQuestion": interviewState.codingChallengeQuestion,
    "interviewSettings": interviewState.interviewSettings,
    "telephoneOrOnsitePhase": interviewState.telephoneOrOnsitePhase,
    "userEmail": interviewState.userEmail,
    "userId": interviewState.userId,
  };

  // Build query string from queryParams
  let queryString = "";
  if (queryParams) {
    queryString = "?" + new URLSearchParams(queryParams).toString();
  }

  console.log(`sending request, queryString: ${queryString}`);
  try {
    // Wrap the axios call in retryRequest
    const response = await retryRequest(() => axios.post(url + queryString, payload));
    const responseChatMessage: ChatMessage = new ChatMessage(
      response.data.sender,
      response.data.recipient,
      response.data.time_sent,
      response.data.message,
      response.data.candidatesAnswerWasAccepted,
      response.data.interviewersClassificationOfCandidatesIntent ?? CandidatesMessageIntent.UNKNOWN
    );
    console.log(`successfully sent request, response: ${JSON.stringify(responseChatMessage)}`);
    return responseChatMessage;
  } catch (error: any) {
    return generateChatMessageFromErrorResponse(error);
  }
}

export async function sendAskInterviewerQuestionRequest(interviewState: InterviewState): Promise<ChatMessage> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/interviewer/respondToMsg`
  return sendRequest(url, interviewState);

}

// create function to send request to get a help or hint from the interviewer const url = `${REACT_APP_BACK_END_BASE_URL}/interviewer/help`;
export async function sendAskInterviewerForHelpRequest(interviewState: InterviewState): Promise<ChatMessage> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/interviewer/help`;
  return sendRequest(url, interviewState);
}

// const url = `${REACT_APP_BACK_END_BASE_URL}/interviewer/feedback`;
export async function sendAskInterviewerForFeedbackRequest(interviewState: InterviewState): Promise<ChatMessage> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/interviewer/giveFeedback`;
  return sendRequest(url, interviewState);
}

//const url = `${REACT_APP_BACK_END_BASE_URL}/interviewer/askQuestion`; trigger question from interviewer
export async function sendTriggerInterviewQuestionRequest(interviewState: InterviewState): Promise<ChatMessage> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/interviewer/askQuestion`;
  return sendRequest(url, interviewState);
}

//const url = `${REACT_APP_BACK_END_BASE_URL}/interviewer/debug`; ask interviewer to help debug code
export async function sendAskInterviewerToDebugCodeRequest(interviewState: InterviewState): Promise<ChatMessage> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/interviewer/debug`;
  return sendRequest(url, interviewState);
}

//const url = `${REACT_APP_BACK_END_BASE_URL}/coach/debug`; ask coach to help debug code
export async function sendAskCoachToDebugCodeRequest(interviewState: InterviewState): Promise<ChatMessage> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/coach/debug`;
  return sendRequest(url, interviewState);
}

//const url = `${REACT_APP_BACK_END_BASE_URL}/coach/simplifyCode`; ask coach to help simplify code
export async function sendAskCoachToSimplifyCodeRequest(interviewState: InterviewState): Promise<ChatMessage> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/coach/simplifyCode`;
  return sendRequest(url, interviewState);
}




/************
 * 
 * TELPHONE OR ONSITE INTERVIEWER REQUESTS
 */


export async function sendTelephoneOrOnsiteInteviewerMessageRequest(interviewState: InterviewState): Promise<ChatMessage> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/interview/telephoneOrOnsite/inteviewer/respondToMsg`
  return sendRequest(url, interviewState);


}

export async function sendTelephoneOrOnsiteInteviewerUserQuickActionsRequest(queryParams: { [key: string]: string }, interviewState: InterviewState): Promise<ChatMessage> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/interview/telephoneOrOnsite/inteviewer/userQuickActions`
  return sendRequest(url, interviewState, queryParams);
}



/*Post interview feedback requests */
export async function sendTelephoneOrOnsiteInteviewerGivePostInterviewFeedbackRequest(telephoneOrOnsiteInterviewState: TelephoneOrOnsiteInterviewState): Promise<string> {
  const url = `${REACT_APP_BACK_END_BASE_URL}${TelephoneOrOnsiteInterviewAPIEndpoints.GIVE_FEEDBACK}`;
  const payload = telephoneOrOnsiteInterviewState;

  try {
    const response = await axios.post(url, payload);
    console.log(`Successfully sent request, response: ${response.data.html}`);
    return response.data.html;
  } catch (error) {
    return generateChatMessageFromErrorResponse(error).message;
  }
}

export async function sendTakeHomeAssessmentInteviewerGivePostInterviewFeedbackRequest(takehomeAssessmentInterviewState: TakeHomeAssessmentInterviewState): Promise<string> {
  const url = `${REACT_APP_BACK_END_BASE_URL}${TakeHomeAssessmentInterviewAPIEndpoints.GIVE_FEEDBACK}`;
  const payload = takehomeAssessmentInterviewState;

  try {
    const response = await axios.post(url, payload);
    console.log(`Successfully sent request, response: ${response.data.html}`);
    return response.data.html;
  } catch (error) {
    return generateChatMessageFromErrorResponse(error).message;
  }
}


export async function storeUserExperienceFeedback(feedbackData: UserExperienceFeedback): Promise<void> {
  const url = `${REACT_APP_BACK_END_BASE_URL}${UserExperienceAPIEndpoints.STORE_USER_EXPERIENCE_FEEDBACK}`;
  const payload = feedbackData;

  try {
    const response = await axios.post(url, payload);
    console.log(`Successfully sent request, response: ${response.data}`);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Failed to get response:', error.message);
    } else {
      console.error('An unexpected error occurred:', error);
    }
  }
}

// Learning section requests
export async function getCourses(courseIds?: number[]): Promise<Course[] | null> {//TODO: decommission, as we should never be fetching all courses
  const baseUrl = `${REACT_APP_BACK_END_BASE_URL}/content/courses`;
  let url = baseUrl;

  // Build query string if courseIds are provided
  if (courseIds && courseIds.length > 0) {
    const queryParams = { "course_ids": courseIds.join(",") };
    url += "?" + new URLSearchParams(queryParams).toString();
  }

  try {
    const response = await axios.get(url);
    if (response.status === 200 && response.data && response.data.data) {
      return response.data.data;
    } else {
      console.error('Invalid response data received after attempting to get courses:', response.data);
      return null;
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error('Axios error:', axiosError.message);
    } else {
      console.error('Error:', error);
    }
    return null;
  }
}

export async function getMinifiedCourses( //TODO: support pagination e.g. fetching specific pages or specifying number of courses per pag
): Promise<(MinifiedCourse)[] | null> {
  const baseUrl = `${REACT_APP_BACK_END_BASE_URL}/content/courses`;
  let url = baseUrl;

  // Build query string with courseIds and minified flag
  const queryParams: Record<string, string> = {};
  
  queryParams["minified"] = "true"; // Add minified flag to query string

  if (Object.keys(queryParams).length > 0) {
    url += "?" + new URLSearchParams(queryParams).toString();
  }

  try {
    const response = await axios.get(url);
    if (response.status === 200 && response.data && response.data.data) {
      return response.data.data;
    } else {
      console.error(
        "Invalid response data received after attempting to get courses:",
        response.data
      );
      return null;
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error("Axios error:", axiosError.message);
    } else {
      console.error("Error:", error);
    }
    return null;
  }
}


// *Uncomment out 'return null' when ready to call endpoint*
export async function getAssessments(): Promise<Assessment[] | null> {
  return null;
  const url = `${REACT_APP_BACK_END_BASE_URL}/content/assessments`;

  try {
    const response = await axios.get(url);
    if (response.status === 200 && response.data && response.data.data) {
      return response.data.data;
    } else {
      console.error('Invalid response data:', response.data);
      return null;
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error('Axios error:', axiosError.message);
    } else {
      console.error('Error:', error);
    }
    return null;
  }
}

export async function executeCode(testCodeParams: TestCaseExecutionRequest): Promise<TestCaseExecutionResponse | null> {
  const url = `${REACT_APP_BACK_END_CODE_EXECUTION_BASE_URL}${CodeExecutionAPIEndpoints.CREATE_TEST_CASE_RESULTS}`;
  const payload = testCodeParams;

  try {
    const response = await axios.post(url, payload);

    if (response.status === 201) {
      return response.data;
    } else {
      console.error("Error:", response.statusText);
      return null;
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.status === 402) {
        return {
          executionStatus: ExecutionStatus.Rejected_Insufficient_Tokens,
          results: {
            allTestCasesPassed: false,
            testCases: [],
          }
        };
      } else {
        // Handle other Axios errors here
        console.error("An Axios error occurred:", error.message);
        return null;
      }
    } else {
      // Handle non-Axios errors here
      console.error("An error occurred:", error);
      return null;
    }
  }
}

export async function getUserSpecialAbilities(userId: number): Promise<any> {
  return null;
  const url = `${REACT_APP_BACK_END_BASE_URL}/users/${userId}/special-abilities`;

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Error:", response.statusText);
      return null;
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      // Handle the AxiosError
      const axiosError = error as AxiosError;
    } else {
      // Handle other types of errors
      console.error('Error:', error);
    }
  }
}

export async function updateUserSpecialAbilities(userId: number, updatedAbilities: UserSpecialAbility[]) {
  return null;
  const url = `${REACT_APP_BACK_END_BASE_URL}/users/${userId}/special-abilities`;

  try {
    const response = await axios.put(url, updatedAbilities);

    if (response.status === 200) {
      console.log("Successfully updated user special abilities");
    } else {
      console.error("Error:", response.statusText);
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      // Handle the AxiosError
      const axiosError = error as AxiosError;
    } else {
      // Handle other types of errors
      console.error('Error:', error);
    }
  }
}

export async function getUserCourseProgress(
  userId: number,
  courseId: number,
  moduleId?: number,
  submodule_id?: number,
  unitId?: number
): Promise<UserProgress[] | null> {
  return null;
  const url = `${REACT_APP_BACK_END_BASE_URL}/users/${userId}/courses/${courseId}/progress`;

  const params = {
    module_id: moduleId,
    submodule_id: submodule_id,
    unit_id: unitId,
  };

  try {
    const response = await axios.get(url, { params });
    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      console.error('Invalid response data:', response.data);
      return null;
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error('Axios error:', axiosError.message);
    } else {
      console.error('Error:', error);
    }
    return null;
  }
}

export async function upsertUserCourseProgress(
  userId: number,
  courseId: number,
  progress: UserProgress
): Promise<void> {
  return;
  const url = `${REACT_APP_BACK_END_BASE_URL}/users/${userId}/courses/${courseId}/progress`;

  try {
    const response = await axios.put(url, progress);
    if (response.status === 204) {
      console.log('User progress upserted successfully');
    } else {
      console.error('Invalid response:', response);
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error('Axios error:', axiosError.message);
    } else {
      console.error('Error:', error);
    }
  }
}


export async function logToBackendLogFile(
  message: string,
  logLevel: string,
  userId?: number,
  userEmail?: string,
): Promise<void> {
  const url = `${REACT_APP_BACK_END_BASE_URL}/persistedMessage`;

  const finalMessage = `[TRACKING] UserID: ${userId}, UserEmail: ${userEmail}, Message: ${message}`;
  const payload = {
    "message": finalMessage,
    "severity": logLevel,
  };

  // Send the POST request asynchronously
  axios.post(url, payload)
    .then(response => {
      if (response.status !== 204) {
        console.debug(`Failed to Log. Received status code: ${response.status}`);
      }
    })
    .catch((error: unknown) => {
      console.debug('Failed to Log. Error:', error);
      // if (axios.isAxiosError(error)) {
      //   const axiosError = error as AxiosError;
      //   console.debug(`Axios error with status code: ${axiosError.response?.status}`);
      // } else {
      //   console.debug('Unknown error occurred while logging');
      // }
    });
}


/////////////////////////////////////
// AI INTERVIEWER COACH INTERACTION //
/////////////////////////////////////
const AI_INTERVIEWER_COACH_BASE_URL = `${REACT_APP_BACK_END_BASE_URL}/ai_assistants/interviewer-coach/predefined-interactions`;

export async function triggerInterviewerCoachRespondToMessageRequest(interactionContext: InterviewerCoachInteractionContext): Promise<ChatMessage> {
  const queryParams = { "desiredAction": "respondToMessage" }
  return sendHttpRequestToInterviewerCoach(HttpMethod.POST, AI_INTERVIEWER_COACH_BASE_URL, interactionContext, queryParams);
}

export async function triggerInterviewerCoachProvideCodeFeedbackRequest(interactionContext: InterviewerCoachInteractionContext): Promise<ChatMessage> {
  const queryParams = { "desiredAction": "provideCodeFeedback" };
  return sendHttpRequestToInterviewerCoach(HttpMethod.POST, AI_INTERVIEWER_COACH_BASE_URL, interactionContext, queryParams);
}

export async function triggerInterviewerCoachProvideHintRequest(interactionContext: InterviewerCoachInteractionContext): Promise<ChatMessage> {
  const queryParams = { "desiredAction": "provideHint" };
  return sendHttpRequestToInterviewerCoach(HttpMethod.POST, AI_INTERVIEWER_COACH_BASE_URL, interactionContext, queryParams);
}

export async function triggerInterviewerCoachProvideClarificationRequest(interactionContext: InterviewerCoachInteractionContext): Promise<ChatMessage> {
  const queryParams = { "desiredAction": "provideClarification" };
  return sendHttpRequestToInterviewerCoach(HttpMethod.POST, AI_INTERVIEWER_COACH_BASE_URL, interactionContext, queryParams);
}

export async function triggerInterviewerCoachDetectBugsInCodeRequest(interactionContext: InterviewerCoachInteractionContext): Promise<ChatMessage> {
  const queryParams = { "desiredAction": "detectBugsInCode" };
  return sendHttpRequestToInterviewerCoach(HttpMethod.POST, AI_INTERVIEWER_COACH_BASE_URL, interactionContext, queryParams);
}

export async function triggerInterviewerCoachRecommendCodeImprovementsRequest(interactionContext: InterviewerCoachInteractionContext): Promise<ChatMessage> {
  const queryParams = { "desiredAction": "recommendCodeImprovements" };
  return sendHttpRequestToInterviewerCoach(HttpMethod.POST, AI_INTERVIEWER_COACH_BASE_URL, interactionContext, queryParams);
}
export async function sendHttpPostRequestToInterviewerCoach(
  url: string,
  interactionContext: InterviewerCoachInteractionContext,
  queryParamsString?: string
): Promise<ChatMessage> {

  const payload = {
    "userId": interactionContext.userId,
    "userEmail": interactionContext.userEmail,
    "candidateName": interactionContext.candidateName,
    "chatHistory": interactionContext.chatHistory,
    "programmingLanguage": interactionContext.programmingLanguage,
    "candidatesCode": interactionContext.candidatesCode,
    "challenge": interactionContext.challenge,
  };
  // intentionally not using try-catch so caller can handle error response e.g. can you use status to decide if to retry
  const response = await axios.post(url + queryParamsString, payload); // axios by default will throw an error if the response contains a status code that falls outside the range of 2xx

  const responseChatMessage: ChatMessage = new ChatMessage(
    response.data.sender,
    response.data.recipient,
    response.data.time_sent,
    response.data.message,
    response.data.candidatesAnswerWasAccepted,
    response.data.interviewersClassificationOfCandidatesIntent ?? CandidatesMessageIntent.UNKNOWN,
  );

  // console.log(`successfully sent post request, response: ${JSON.stringify(responseChatMessage)}`);
  return responseChatMessage;
}


export async function sendHttpRequestToInterviewerCoach(
  httpMethod: HttpMethod,
  url: string,
  interactionContext: InterviewerCoachInteractionContext,
  queryParams?: { [key: string]: string }
): Promise<ChatMessage> {

  // Build query string from queryParams
  let queryString = "";
  if (queryParams) {
    queryString = "?" + new URLSearchParams(queryParams).toString();
  }

  try {
    return await retryRequest(() => {
      switch (httpMethod) {
        case HttpMethod.POST:
          return sendHttpPostRequestToInterviewerCoach(url, interactionContext, queryString);
        default:
          throw new Error(`Unsupported HTTP method: ${httpMethod}`);
      }
    });
  } catch (error: any) {
    return generateChatMessageFromErrorResponse(error);
  }
}




// Function to send CV optimization request
// Define the structure of the CV optimization template parameters
export interface CvOptimisationRequestTemplateParams {
  serviceType: string;  // The type of service requested, e.g., "Basic" or "Premium"
  customerEmail: string;        // The user's email address
  cvText: string;       // The main content of the CV
  cvExtraText: string;  // Any additional information for the CV
}

export async function sendCVOptimisationRequestToBackend(templateParams: CvOptimisationRequestTemplateParams): Promise<boolean> {
  const url = `${process.env.REACT_APP_BACK_END_BASE_URL}/email_services/cv-optimization-request`;

  try {
    // Use retryRequest utility to handle retries
    await retryRequest(async () => {
      await axios.post(url, templateParams);
    });
    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.message);
    } else {
      console.error('An unexpected error occurred:', error);
    }
    return false;
  }
}

export const createUserInBackend = async (
  userData: {
    userName: string;
    email: string;
    password: string;
    invitationCode: string;
  },
): Promise<boolean> => { // TODO: tight coupling introduced by inter with setError, refactor to return error message
  try {
    //log.debug("sending request to backend to create user")
    const response = await axios.post(`${REACT_APP_BACK_END_BASE_URL}/users`, {
      userName: userData.userName,
      email: userData.email,
      password: userData.password,
      invitationCode: userData.invitationCode,
    });
    if (response.status === 201) {
      //log.info("User created in backend successfully");
      return true;
    } else {
      logToBackendLogFile(`Error creating user in backend: ${response.data.message}`, "error");
      return false;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) { // This is a type guard provided by Axios
      // Now TypeScript knows error is of type AxiosError
      if (error.response) {
         logToBackendLogFile(`Error creating user in backend: ${error.response.data.message}`, "error");
      } else {
        // Handle case where error.response is undefined
         logToBackendLogFile(`An undefined error occurred while creating user in backend`, "error");
      }
    } else {
      // Handle any other errors that might have occurred and were not AxiosErrors
       logToBackendLogFile(`Error with details: ${error} occurred while creating user in backend`, "error");
    }
    return false;
  }
};