// File: utils/initBuilder.ts
import { builder } from '@builder.io/react';

const apiKey = process.env.REACT_APP_BUILDER_IO_API_KEY;
if (!apiKey) {
  console.error('Builder.io API key is missing. Please set REACT_APP_BUILDER_IO_API_KEY in your environment.');
} else {
  builder.init(apiKey);
}

export default builder;
