import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import builder from '../builderio/initBuilder';
import BlogPostTile from './BlogPostTile';
import Button from '@mui/material/Button';
import './css/BlogHome.css';
import { BLOG_PAGE_PATH } from '../utils/Constants';
import { DiscountBannerButton } from '../utils/BannerButton';
import ShareButton, { ShareButtonContainer } from '../utils/ShareButton';
import StateAccessor from '@/StateAccessor';
import { logToBackendLogFile } from '../externalLayerAccessor/BackEndRequests';
import NotFoundPage from '../pages/NotFoundPage';

const DEFAULT_COLORS = {
  bgColor: '#ffffff',
  titleFontColor: '#000000',
};

interface BlogPost {
  id: string;
  title: string;
  content: string;
  slug: string;
  bgColor?: string;
  titleColor?: string;
}

const BlogHome: React.FC = () => {
  const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null);
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const contentRef = useRef<HTMLDivElement>(null);
  const { postId, slug } = useParams<{ postId: string; slug: string }>();
  const navigate = useNavigate();
  const { userId } = useContext(StateAccessor);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    setError(null);

    builder
      .getAll('blogpage', { options: { noTargeting: true }, signal: controller.signal })
      .then((data: any[]) => {
        const formattedPosts = data.map((post) => ({
          id: post.data.id || '',
          title: post.data.title || 'Untitled Post',
          content: post.data.content || '<p>No content available</p>',
          slug: post.data.slug || '',
          bgColor: post.data.bgColor || DEFAULT_COLORS.bgColor,
          titleColor: post.data.titleColor || DEFAULT_COLORS.titleFontColor,
        }));
        setPosts(formattedPosts);
      })
      .catch((err: Error) => {
        if (err.name !== 'AbortError') {
          console.error('Error fetching blog posts:', err);
          setError('Failed to fetch blog posts. Please try again later.');
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (postId) {
      const post = posts.find((p) => p.id === postId);
      if (post) {
        const isSlugValid = slug ? post.slug.toLowerCase() === slug.toLowerCase() : true;

        if (isSlugValid) {
          setSelectedPost(post);
        } else {
          setSelectedPost(null);
        }
      } else {
        builder
          .get('blogpage', { query: { 'data.id': postId } })
          .toPromise()
          .then((data: any) => {
            if (data) {
              setSelectedPost({
                id: data.data.id,
                title: data.data.title,
                content: data.data.content,
                slug: data.data.slug,
                bgColor: data.data.bgColor || DEFAULT_COLORS.bgColor,
                titleColor: data.data.titleColor || DEFAULT_COLORS.titleFontColor,
              });
            } else {
              setSelectedPost(null);
            }
          })
          .catch(() => setSelectedPost(null));
      }
    } else {
      setSelectedPost(null);
    }

    if (userId) {
      logToBackendLogFile(`Viewed Blog post with postId: ${postId}`, 'info', userId);
    }
  }, [postId, slug, posts, userId]);

  useEffect(() => {
    if (selectedPost && contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedPost]);

  const handleSelectPost = useCallback((post: BlogPost) => {
    setSelectedPost(post);
    navigate(`${BLOG_PAGE_PATH}/${post.id}/${post.slug}`, { replace: true });
  }, [navigate]);

  const filteredPosts = useMemo(() => {
    return posts.filter((post) => post.id !== selectedPost?.id);
  }, [posts, selectedPost]);

  const sanitizedContent = selectedPost
    ? DOMPurify.sanitize(selectedPost.content, {
        ALLOWED_TAGS: [
          'b',
          'i',
          'em',
          'strong',
          'a',
          'p',
          'div',
          'ul',
          'li',
          'ol',
          'h1',
          'h2',
          'h3',
          'h4',
          'iframe',
          'img', // Allow images
        ],
        ALLOWED_ATTR: ['href', 'target', 'rel', 'src', 'alt', 'width', 'height', 'frameborder', 'allow', 'allowfullscreen', 'style'], // Allow essential attributes for images and videos
      })
    : '';

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return (
      <div className="error-state">
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  if (postId && !selectedPost) {
    return <NotFoundPage />;
  }

  return (
    <div>
      {posts.length > 0 && (
        <div className="featured-post">
          <h1>Featured</h1>
          <h2>{posts[0].title}</h2>
          <Button variant="contained" color="primary" className="read-blog-post-button" onClick={() => handleSelectPost(posts[0])}>
            Click To Read
          </Button>
        </div>
      )}

      <div className="blog-grid">
        {posts.map((post) => (
          <BlogPostTile
            key={post.id}
            title={post.title}
            bgColor={post.bgColor || DEFAULT_COLORS.bgColor}
            titleFontColor={post.titleColor || DEFAULT_COLORS.titleFontColor}
            onClick={() => handleSelectPost(post)}
          />
        ))}
      </div>

      <div className="blog-content" ref={contentRef}>
        {selectedPost ? (
          <>
            <ShareButtonContainer url={window.location.href} title={selectedPost.title} centered />
            <h1>{selectedPost.title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            />
            <div className="share-button-container">
              <ShareButton url={window.location.href} title={selectedPost.title} />
            </div>
          </>
        ) : null}
      </div>

      {selectedPost && (
        <div className="blog-grid-bottom-container">
          <h2>Other Blog Posts</h2>
          <div className="blog-grid-bottom">
            {filteredPosts.map((post) => (
              <BlogPostTile
                key={post.id}
                title={post.title}
                bgColor={post.bgColor || DEFAULT_COLORS.bgColor}
                onClick={() => handleSelectPost(post)}
              />
            ))}
          </div>
        </div>
      )}

      <DiscountBannerButton appearAfterTimeInSec={null} />
    </div>
  );
};

export default BlogHome;
