import React, { useContext } from 'react';
import '../css/Explanations.css';
import DropdownItem from '../utils/DropdownItem';
import { renderContentInDropDown } from '../utils/HelperFunction';
import { AccessLevel, Unit } from '@/utils/Constants';
import StateAccessor from '@/StateAccessor';
import { userHasPremiumSubscriptionPrivilege } from '@/utils/PermissionUtils';
import PremiumContentAccessDeniedMessage from '@/utils/PremiumContentAccessDeniedMessage';

interface KeyAssociationProps {
  association: string;
}

const KeyAssociation: React.FC<KeyAssociationProps> = ({ association }) => (
  <div className="key-association-icon">{association}</div>
);
export interface ExplanationsProps {
  unit: Unit;
}

const Explanations: React.FC<ExplanationsProps> = ({ unit }) => {
  const { userSubscriptionTier } = useContext(StateAccessor);

  const displayContent = (): boolean => {
    return (unit.access_level !== AccessLevel.Premium || userHasPremiumSubscriptionPrivilege(userSubscriptionTier));
  }

  return displayContent() ? (
    <div className='explanations-container'>
      <div className="explanations-content-container">

        {unit.explanations && (
          <ul className="explanations-items">
            {renderContentInDropDown('Diagram', null, null, unit.explanations.diagram_url)}
            {renderContentInDropDown('Test Cases', unit.explanations.test_cases_markdown, null, null)}
            {renderContentInDropDown('Quick Solution', unit.explanations.quick_markdown, unit.explanations.quick_html, null)}
            {renderContentInDropDown('Explain like I\'m 5', unit.explanations.explain_like_im_five_years_old_markdown, unit.explanations.explain_like_im_five_years_old_html, null)}
            {renderContentInDropDown('Thought process', unit.explanations.thought_process_markdown, unit.explanations.thought_process_html, null)}
            {renderContentInDropDown('Example-based', unit.explanations.example_based_markdown, unit.explanations.example_based_html, null)}
          </ul>
        )}
        {unit.challenge.key_associations && unit.challenge.key_associations.length > 0 && (
          <DropdownItem title="Key associations">
            <div className="key-associations-container">
              {unit.challenge.key_associations.map((association, index) => (
                <KeyAssociation key={index} association={association} />
              ))}
            </div>
          </DropdownItem>
        )}
      </div>
    </div>
  ) : (
    <PremiumContentAccessDeniedMessage />
  );
};

export default Explanations;