// File: components/NotFoundPage.tsx
import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        backgroundColor: '#f8f2a8', // Consistent with BlogContent background
        borderRadius: '10px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        padding: '40px',
      }}
    >
      <Box>
        <Typography
          variant="h1"
          sx={{
            fontSize: '5rem',
            fontWeight: 'bold',
            mb: 2,
            color: '#464444', // Consistent with titles in your app
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
          }}
        >
          404
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mb: 2,
            color: '#555',
            fontFamily: 'Arial Rounded MT, sans-serif',
          }}
        >
          Oops! The page you're looking for doesn't exist.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 4,
            color: '#555',
            fontSize: '18px',
            lineHeight: '1.5',
          }}
        >
          It might have been removed, renamed, or the URL might be incorrect.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/')}
          sx={{
            textTransform: 'none',
            fontSize: '16px',
            padding: '10px 20px',
            backgroundColor: '#3498db',
            '&:hover': {
              backgroundColor: '#1d70b8', // Darker shade on hover
            },
          }}
        >
          Go Back to Home
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
