import React from 'react';
import { useParams } from 'react-router-dom';
import { ChallengeType, Difficulty, AssessmentAttemptReport } from '../utils/Constants';
import '../css/Feedback.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import CodeIcon from '@mui/icons-material/Code';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Ribbon from '../utils/Ribbon';
import DropdownItem from '../utils/DropdownItem';
import SolutionContent from './SolutionContent';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
interface FeedbackProps {
  assessmentAttemptReport: AssessmentAttemptReport | null;
  disabled?: boolean;
}

const AssessmentUnitFeedback: React.FC<FeedbackProps> = ({ assessmentAttemptReport, disabled = false }) => {

  const params = useParams();
  if (!params.assessment_id) {
    return null;
  }

  if (!assessmentAttemptReport) {
    return null;
  }
  if (disabled) {
    return null;
  }

  return (
    <div className="feedback-container">
      <h1 className="feedback-container-title">Results</h1>
      {assessmentAttemptReport.unitAttemptReports.map((unitAttemptReport, index) => (
        <div key={index} className="assessment-box">
          <div className="assessment-top-row">
            <div className="assessment-top-left-container">
              <div
                className="challenge-type"
                data-tooltip-id="challenge-type-tooltip"
                data-tooltip-content={
                  unitAttemptReport.unit.challenge.challenge_type === ChallengeType.CODING
                    ? 'Coding challenge'
                    : 'Knowledge challenge'
                }
              >
                {unitAttemptReport.unit.challenge.challenge_type === ChallengeType.CODING ? (
                  <CodeIcon sx={{ width: '64px', height: '64px' }} />
                ) : (
                  <DescriptionIcon sx={{ width: '64px', height: '64px' }} />
                )}
              </div>
              <div className="assessment-info-container">
                <div className="assessment-unit-title-and-ribbon-container">
                  <h3 className="assessment-unit-title-and-ribbon">
                    {unitAttemptReport.unit.title}
                    <Ribbon
                      parameter={unitAttemptReport.unit.challenge.difficulty}
                      data={{
                        [Difficulty.Straightforward]: { color: 'green', text: 'Straightforward' },
                        [Difficulty.ModeratelyChallenging]: { color: 'yellow', text: 'Moderately Challenging' },
                        [Difficulty.Challenging]: { color: 'red', text: 'Challenging' },
                      }}
                    />
                  </h3>
                </div>
                <div className="assessment-challenge-title-container">
                  <h5 className="assessment-challenge-title">{unitAttemptReport.unit.challenge.title}</h5>
                </div>
                {unitAttemptReport.unit.challenge.challenge_type === ChallengeType.KNOWLEDGE && unitAttemptReport.unit && (
                  <DropdownItem title="Solution">
                    <SolutionContent
                      unit={unitAttemptReport.unit}
                      acceptableAnswers={unitAttemptReport.unit.challenge.acceptable_answers}
                      challenge={unitAttemptReport.unit.challenge}
                    />
                  </DropdownItem>
                )}
                {unitAttemptReport.unit.challenge.challenge_type === ChallengeType.CODING && unitAttemptReport.unit.challenge.solution_by_language && (
                  <DropdownItem title="Solution">
                    <SolutionContent
                      unit={unitAttemptReport.unit}
                      challenge={unitAttemptReport.unit.challenge}
                      solutionByLanguage={unitAttemptReport.unit.challenge.solution_by_language}
                    />
                  </DropdownItem>
                )}
              </div>
            </div>
            <div className="answer-correctness-symbol-container">
              {unitAttemptReport.answeredCorrectly === true && (
                <CheckCircleIcon sx={{ width: '64px', height: '64px' }} className="correct-icon" />
              )}
              {unitAttemptReport.answeredCorrectly === false && (
                <CancelIcon sx={{ width: '64px', height: '64px' }} className="incorrect-icon" />
              )}
              {(unitAttemptReport.answeredCorrectly === null || unitAttemptReport.answeredCorrectly === undefined) && (
                // You can choose any of the three icons mentioned above. Here's an example with HelpOutlineIcon:
                <HelpOutlineIcon sx={{ width: '64px', height: '64px' }} className="did-not-attempt-icon" />
              )}
            </div>

          </div>
        </div>
      ))}
      <Tooltip id="challenge-type-tooltip" />
    </div>
  );
};

export default AssessmentUnitFeedback;
