import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

// Import Material UI icons
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

// Import our CSS
import "../css/MockInterviewButtons.css";

// Import URL constants
import { HUMAN_INTERVIEWER_COACH_PATH, AI_INTERVIEW_SELECTION_PAGE_PATH } from '../utils/Constants';

interface MockInterviewButtonsProps {
  // No props needed for now.
}

const MockInterviewButtons: React.FC<MockInterviewButtonsProps> = () => {
  const navigate = useNavigate();

  const handleHumanInterviewNavigation = () => {
    navigate(HUMAN_INTERVIEWER_COACH_PATH);
  };

  const handleAiInterviewNavigation = () => {
    navigate(AI_INTERVIEW_SELECTION_PAGE_PATH);
  };

  return (
    <div className="mock-interview-buttons-wrapper">
      <Button
        variant="contained"
        className="mock-interview-button-primary"
        startIcon={<SupervisorAccountIcon />}
        onClick={handleHumanInterviewNavigation}
      >
        Try Mock Interviews
      </Button>
      <Button
        variant="contained"
        className="mock-interview-button-secondary"
        startIcon={<AutoAwesomeIcon />}
        onClick={handleAiInterviewNavigation}
      >
        Try AI Mock Interviews
      </Button>
    </div>
  );
};

export default MockInterviewButtons;
