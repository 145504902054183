// PrepSessionHighlights.tsx
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from "react-feather";
import './css/PrepSessionHighlights.css';
import { BookSessionButton } from "./BookingAndPayment";
import { CompanyName } from '@/utils/Constants';

// Enum for Prep Session Types
export enum PrepSessionType {
    DEFAULT = 'mock-highlight',
    MOCK_INTERVIEW_SESSION = 'mock-interview',
    COACHING_SESSION = 'coaching-session',
    STRATEGY_SESSION = 'strategy-session',
}

// Interface for Prep Session Highlights
export interface PrepSessionHighlight {
    id: string;
    companyName: CompanyName;
    embedUrl: string;
    title: string;
    altText?: string;
    caption?: string;
}

// PrepSessionHighlightCard Component
interface PrepSessionHighlightCardProps {
    prepSessionHighlight: PrepSessionHighlight;
    prepSessionType: PrepSessionType;
}

const PrepSessionHighlightCard: React.FC<PrepSessionHighlightCardProps> = React.memo(
    ({ prepSessionHighlight, prepSessionType }) => {
        const handleVideoClick = useCallback(() => {
            // Optional: Implement any additional logic if needed when video is clicked
            // Currently, clicking does not perform any action since iframe is embedded
        }, []);

        return (
            <div className={`mock-highlight-card ${prepSessionType}`}>
                <button
                    className="video-thumbnail-container"
                    onClick={handleVideoClick}
                    aria-label={`Play video: ${prepSessionHighlight.title} by ${prepSessionHighlight.companyName}`}
                >
                    {/* Render YouTube embed in iframe */}
                    <iframe
                        loading="lazy"
                        src={prepSessionHighlight.embedUrl}
                        title={prepSessionHighlight.title}
                        className="video-iframe"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        aria-label={prepSessionHighlight.altText || `Video titled ${prepSessionHighlight.title}`}
                    />

                </button>
                <div className="mock-text-container">
                    <p className='mock-brand'>{prepSessionHighlight.companyName}</p>
                    <h3 className="mock-title">{prepSessionHighlight.title}</h3>
                    {prepSessionHighlight.caption && (
                        <p className="mock-caption">{prepSessionHighlight.caption}</p>
                    )}
                </div>
            </div>
        );
    }
);

// PrepSessionHighlightsBoard Component
interface PrepSessionHighlightsBoardProps {
    prepSessionHighlights: PrepSessionHighlight[];
    itemsPerPage?: number;
    prepSessionType?: PrepSessionType;
}

const PrepSessionHighlightsBoard: React.FC<PrepSessionHighlightsBoardProps> = React.memo(
    ({ prepSessionHighlights, itemsPerPage = 3, prepSessionType = PrepSessionType.MOCK_INTERVIEW_SESSION }) => {
        const [currentPage, setCurrentPage] = useState<number>(1);
        const totalPages = useMemo(
            () => Math.ceil(prepSessionHighlights.length / itemsPerPage),
            [prepSessionHighlights, itemsPerPage]
        );

        const pageContainers = useMemo(
            () => Array.from({ length: totalPages }, (_, i) =>
                prepSessionHighlights.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
            ),
            [prepSessionHighlights, itemsPerPage, totalPages]
        );

        const carouselSlidesRef = useRef<HTMLDivElement>(null);

        const handlePageChange = useCallback(
            (delta: number) => {
                setCurrentPage(prevPage => {
                    const newPage = prevPage + delta;
                    const wrappedPage = (newPage - 1 + totalPages) % totalPages + 1;
                    return wrappedPage;
                });
            },
            [totalPages]
        );

        const prevPage = useCallback(() => handlePageChange(-1), [handlePageChange]);
        const nextPage = useCallback(() => handlePageChange(1), [handlePageChange]);

        useEffect(() => {
            const translatePercentage = `-${(currentPage - 1) * 100}%`;
            if (carouselSlidesRef.current) {
                carouselSlidesRef.current.style.setProperty('--carousel-translate', translatePercentage);
            }
        }, [currentPage]);

        if (prepSessionHighlights.length === 0) {
            return (
                <div className="carousel-container">
                    <p className="no-highlights-message">No highlights available at the moment.</p>
                </div>
            );
        }

        return (
            <div className="carousel-container">
                <div
                    className="carousel-slides"
                    ref={carouselSlidesRef}
                >
                    {pageContainers.map((pageHighlights, pageIndex) => (
                        <div key={`carousel-page-${pageIndex}`} className="carousel-page">
                            <div className="carousel-grid">
                                {pageHighlights.map((highlight) => (
                                    <PrepSessionHighlightCard
                                        key={highlight.id}
                                        prepSessionHighlight={highlight}
                                        prepSessionType={prepSessionType}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Carousel navigation controls */}
                <div className="carousel-controls">
                    <button onClick={prevPage} className="carousel-button prev" aria-label="Previous Page">
                        <ChevronLeft size={40} />
                    </button>
                    <button onClick={nextPage} className="carousel-button next" aria-label="Next Page">
                        <ChevronRight size={40} />
                    </button>
                </div>

                {/* Carousel indicators */}
                <div className="carousel-indicators">
                    {Array.from({ length: totalPages }).map((_, i) => (
                        <button
                            key={`indicator-dot-${i}`}
                            className={`indicator-dot ${currentPage === i + 1 ? 'active' : ''}`}
                            onClick={() => setCurrentPage(i + 1)}
                            aria-label={`Go to page ${i + 1}`}
                        ></button>
                    ))}
                </div>
            </div>
        );
    }
);

// Main PrepSessionHighlights Component
const PrepSessionHighlights: React.FC = () => {
    const sampleHighlights: PrepSessionHighlight[] = [
        {
            id: '0',
            companyName: CompanyName.META,
            title: "Production Engineering Troubleshooting - Coached Mock Interview Session",
            embedUrl: "https://www.youtube.com/embed/MRzAWUAhgkw?si=QoNfW3LkSpclt9QU",
            altText: "Meta Production Engineering Troubleshooting Mock Interview Coaching Session",
            caption: "This video showcases a sample coached mock interview session for the Meta production engineering troubleshooting round. In this session the candidate troubleshoots a scenario where exactly one of the 101 instances of an application server is failing to serve some requests"
        },
        {
            id: '10',
            companyName: CompanyName.META,
            title: "Production Engineering - Coached Mock Interview Session",
            embedUrl: "https://www.youtube.com/embed/D7-b5yGqliA?si=A6B-UCIXu9tscgBq",
            altText: "Meta Production Engineering Mock Interview Coaching Session",
            caption: "This video showcases a sample coached mock interview session for the Meta production engineering coding round. In this session the candidate covers a sample coding question that tests their understanding of using linux commands to extract data from a file."
        },
        {
            id: '20',
            companyName: CompanyName.AMAZON,
            title: "System Design Mock Interview Session - FAANG",
            embedUrl:"https://www.youtube.com/embed/P8-eceXL8VY?si=pRJ00hxd9Z2mlIFM",
            altText: "System Design Mock Interview Session",
            caption: "This video showcases highlights from a system design mock interview session. You can see how the candidate handles the requirements gathering, and also an excerpt from the section where the candidate receives actionable feedback to improve. This particular candidate had 7 dedicated prep sessions with Coditioning, and ended up receiving an offer from Amazon at the SDE II level"
        },
        {
            id: '30',
            companyName: CompanyName.META,
            title: "Coached Mock Interview Session - Meta Coding Round",
            embedUrl: "https://www.youtube.com/embed/TD-kXXFC808?si=MpbFEWl5yjEdtanV",
            altText: "Meta Coding Round Mock Interview Coaching Session",
            caption: "This video showcases a sample coached mock interview session for the Meta coding round. These sessions simulate a realistic mock interview, where the interviewer plays a dual role of both coaching and interviewing the candidate. This approach allows the candidate to receive real-time feedback—i.e., just-in-time feedback—rather than waiting until the end of the interview to receive insights."
        },
        {
            id: '40',
            companyName: CompanyName.AMAZON,
            title: "Coached Mock Interview Session - Amazon Coding Round",
            embedUrl: "https://www.youtube.com/embed/rALXoYpGD3c?si=sINScgKEGLIZQZ8c",
            altText: "Amazon Coding Round Mock Interview Coaching Session",
            caption: "This video showcases a sample coached mock interview session for a candidate who requested the session to be tailored to both the Meta and Amazon coding rounds. You will find at least two moments where the candidate makes consequential mistakes, and you'll see how the coach provides real-time feedback and guidance to help prevent such mistakes in the future."
        },  
        {
            id: '50',
            companyName: CompanyName.META,
            title: "Data Engineering - Python Basics - Coaching Session",
            embedUrl:"https://www.youtube.com/embed/Wk4fMLDG1Zk?si=3rTiBO_NVOvK3PoF",
            altText: "Data Engineering - Python Basics - Coaching Session",
            caption: "In this video, a data engineering candidate, rusty with Python, receives coaching on a typical Python interview question for a data engineering role. This excerpt is taken from the full coaching session."
        } 

    ];

    return (
        <div className="mock-highlights-container">
            <div className="mock-highlights-text-container">
                <h1>Past mock and coaching session highlights</h1>
                <p>
                    To get a feel for what our mock and coaching sessions are like, check out some of the highlights below!
                </p>
            </div>

            <div className='book-session-button-container'>
                <BookSessionButton />
            </div>

            <div className="mock-board-container">
                <PrepSessionHighlightsBoard 
                    prepSessionHighlights={sampleHighlights}
                    itemsPerPage={6}
                    prepSessionType={PrepSessionType.MOCK_INTERVIEW_SESSION}
                />
            </div>

            <div className='book-session-button-container'>
                <BookSessionButton />
            </div>
        </div>
    );
}

export default PrepSessionHighlights;
