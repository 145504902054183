import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  WORKSHOPS_PAGE_PATH,
  HUMAN_INTERVIEWER_COACH_PATH,
  AI_INTERVIEW_SELECTION_PAGE_PATH,
} from '../utils/Constants';
import { Button } from '@mui/material';

// Import Material UI icons
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import '../css/CoursesSidebar.css';

interface Section {
  id: string;
  title: string;
  description: string;
  link: string;
  buttonText: string;
}

const CoursesSidebar: React.FC = () => {
  const navigate = useNavigate();

  const sections: Section[] = [
    {
      id: 'mock-interviews',
      title: 'Avoid Surprises with our Mock Interview Service',
      description: `Assess your interview readiness objectively, and uncover any weaknesses before, not during the actual interview. Try our tailored mock interviews which help you prepare under realistic conditions.`,
      link: HUMAN_INTERVIEWER_COACH_PATH,
      buttonText: 'Learn More',
    },
    {
      id: 'ai-mock-interviews',
      title: 'AI-Powered Mock Interviews',
      description: `Want to practice real interview questions, under more realistic conditions than leetcode problems? Then try past interview questions in the presence of an AI interviewer to see how ready you are.`,
      link: AI_INTERVIEW_SELECTION_PAGE_PATH,
      buttonText: 'Try AI mocks',
    },
    {
      id: 'workshop',
      title: 'Interview Prep and Chill?',
      description: `Join our next interview prep workshop. It's super casual, ask any questions, work on a real interview problem, get feedback, and hang out with others on the interview prep journey.`,
      link: WORKSHOPS_PAGE_PATH,
      buttonText: 'Register',
    },
  ];

  // Handler that uses useNavigate for navigation
  const handleNavigation = (link: string) => {
    navigate(link);
  };

  // Return the appropriate icon based on the section id
  const getIconForSection = (id: string) => {
    switch (id) {
      case 'mock-interviews':
        return <SupervisorAccountIcon />;
      case 'ai-mock-interviews':
        return <AutoAwesomeIcon />;
      case 'workshop':
        return <EventAvailableIcon />;
      default:
        return null;
    }
  };

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-container">
        {sections.map((section) => (
          <div key={section.id} className="sidebar-section">
            <h6>{section.title}</h6>
            <p>{section.description}</p>
            <div className="section-link">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleNavigation(section.link)}
                aria-label={`Learn more about ${section.title}`}
                style={{ textTransform: 'none' }}
                startIcon={getIconForSection(section.id)}
                className="sidebar-button"
              >
                {section.buttonText}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesSidebar;
