
/*

Classes & Data
*/

import { DiscountConfig } from "./DiscountConfig";
import { DiscountId } from "./DiscountId";



export interface DiscountInfo {
    discountPercentage: number;       // e.g. 0, 10, 20, etc. percentage discount
    promoCode: string;      // e.g. 'SAVE20'
    endDate: Date | null;   // null means run indefinitely
}

export const DISCOUNTS: Record<DiscountId, DiscountInfo> = {
    [DiscountId.NONE]: {
        discountPercentage: 0,
        promoCode: '',
        endDate: null,
    },
    [DiscountId.DEFAULT]: {
      discountPercentage: 30,
      promoCode: 'GETINTERVIEWREADYANDSAVE30',
      endDate: null,
  },
    [DiscountId.SAVE10]: {
      discountPercentage: 10,
      promoCode: 'GETTRULYREADYANDSAVE',
      endDate: null,
    },
    [DiscountId.SAVE20]: {
        discountPercentage: 20,
        promoCode: 'SAVE20', // TODO: update to valid codes before ever using these
        endDate: null,
    },
    [DiscountId.SAVE30]: {
        discountPercentage: 30,
        promoCode: 'GETINTERVIEWREADYANDSAVE30',
        endDate: new Date(Date.UTC(2025, 3, 1)), // May 1, 2023 (month is 0-indexed)
    },
    [DiscountId.SAVE33]: {
        discountPercentage: 33,
        promoCode: 'GETTRULYREADYFORLESS',
        endDate: new Date(Date.UTC(2025, 2, 1)), // 1st March 2025
    },
    [DiscountId.SAVE40]: {
        discountPercentage: 40,
        promoCode: 'SAVE40', // TODO: update to valid codes before ever using these
        endDate: null, // Indefinite discount
    },
};


/*
Functions
*/

/**
 * Returns the currently active discount's info, 
 * or the NONE discount if it's expired or something is invalid.
 */
export function getCurrentDiscount() {
  const activeId = getActiveDiscountId();

  // Safety check: if it's not in the dictionary, treat as NONE
  if (!DISCOUNTS[activeId]) {
    return DISCOUNTS[DiscountId.NONE];
  }

  const discountInfo = DISCOUNTS[activeId];
  const { endDate } = discountInfo;

  // If endDate is non-null, check if it's in the future
  if (endDate && endDate.getTime() < Date.now()) {
    // Discount expired, return default discount
    return DISCOUNTS[DiscountId.DEFAULT];
  }

  return discountInfo;
}

/**
 * Returns the currently active discount ID.
 */
export function getActiveDiscountId(): DiscountId {
  return DiscountConfig.activeDiscountId;
}