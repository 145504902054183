// File: BlogPostTile.tsx
import React from 'react';
import Button from '@mui/material/Button';
import './css/BlogPostTile.css';

interface Props {
  title: string;
  bgColor: string;
  titleFontColor?: string;
  onClick: () => void;
}

// Centralized default button styles
const DEFAULT_BUTTON_STYLE = {
  border: '5px solid white',
  marginLeft: '10px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const BlogPostTile: React.FC<Props> = ({
  title,
  bgColor,
  titleFontColor = '#000',
  onClick,
}) => {
  /**
   * Dedicated handler to prevent event bubbling
   * before calling the parent onClick logic.
   */
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <div
      className="blog-post-tile"
      style={{ backgroundColor: bgColor, color: titleFontColor }}
      onClick={onClick}
    >
      <h3 className="blog-post-title">{title}</h3>

      <Button
        variant="contained"
        color="primary"
        className="read-blog-post-button"
        aria-label={`Read blog post: ${title}`}
        onClick={handleButtonClick}
        sx={{
          backgroundColor: bgColor,
          ...DEFAULT_BUTTON_STYLE,
        }}
      >
        Read
      </Button>
    </div>
  );
};

export default BlogPostTile;
